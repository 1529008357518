const TOKEN_KEY = 'assetstracker_token';
const PROFILE_KEY = 'profile';
const ORGANIZATION_KEY = 'selected_organization'
const DARK_MODE = false;

const TokenService = {
  // manage token local storage
  get() {
    return localStorage.getItem(TOKEN_KEY) || null;
  },
  set(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },
  remove() {
    localStorage.removeItem(TOKEN_KEY);
  },
};

const DarkMode = {
  get() {
    return localStorage.getItem(DARK_MODE);
  },
  set(darkMode) {
    localStorage.setItem(DARK_MODE, darkMode);
  },
};

const OrganizationService = {
  get() {
    return localStorage.getItem(ORGANIZATION_KEY);
  },
  set(organizationId) {
    localStorage.setItem(ORGANIZATION_KEY, organizationId);
  },
};

const defaultUser = {
  name: null,
  email: null,
};

const ProfileService = {
  // manage profile local storage
  get() {
    return localStorage.getItem(PROFILE_KEY) ? JSON.parse(localStorage.getItem(PROFILE_KEY)) : defaultUser;
  },
  set(profile) {
    localStorage.setItem(PROFILE_KEY, JSON.stringify(profile));
  },
  remove() {
    localStorage.removeItem(PROFILE_KEY);
  },
};

export {
  TokenService,
  ProfileService,
  OrganizationService,
  DarkMode,
};
