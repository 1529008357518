import { TokenService, ProfileService } from '@/services/local-storage';
import api from '@/services/api';
import router from '@/router';

export const state = () => ({
  token: TokenService.get(),
  isLoggedIn: !!TokenService.get(),
  errorMsg: '',
  errorStatus: '',
  profile: ProfileService.get(),
});

export const getters = {
  profile: ({ profile }) => profile,
  organizations: ({ profile }) => profile.organizations,
  errorStatus: (state) => state.errorStatus,
};

export const mutations = {
  SAVE_TOKEN(state, token) {
    state.token = token;
    state.isLoggedIn = true;
  },
  SET_ERROR_MSG(state, val) {
    state.errorMsg = val;
  },
  SET_ERROR_STATUS(state, val) {
    state.errorStatus = val;
  },
  SAVE_USER(state, userData) {
    state.profile = userData;
  },
  LOGOUT(state) {
    state.profile = ProfileService.get();
    state.token = null;
    state.isLoggedIn = false;
  },
};

export const actions = {
  async userLogin({ commit, dispatch }, { email, password, redirect }) {
    try {
      // Resets errors in store
      // commit('SET_ERROR_MSG', '');
      commit('SET_ERROR_STATUS', '');

      const response = await api.login({
        email: email,
        password: password,
      });

      if (response.status === 200) {
        TokenService.set(response.data.token);
        commit('SAVE_TOKEN', response.data.token);

        // get user profile
        await dispatch('getProfile');

        // redirect url
        if (redirect) {
          router.push({
            path: redirect,
          });
        } else {
          router.push('/');
        }
      }
    } catch (error) {
      console.log(error.response)
      // commit('SET_ERROR_MSG', error.response.data?.message || error);
      commit('SET_ERROR_STATUS', error.response.status);
    }
  },

  userLogout({ commit }) {
    TokenService.remove();
    ProfileService.remove();
    commit('LOGOUT');
    router.push({
      path: '/login',
    });
  },

  async getProfile({ commit }) {
    try {
      const { data } = await api.getProfile();
      ProfileService.set(data.data);
      commit('SAVE_USER', data.data);
    } catch (error) {
      commit('SET_ERROR_MSG', error.response.data?.message || error);
    }
  },
};
