import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter() {
      store.dispatch('auth/userLogout');
    },
  },
  // Profile
  {
    path: "/profile",
    name: "profile",
    meta: { title: "Profile", authRequired: true },
    component: () => import("@/views/profile/Profile.vue"),
  },
  // Settings
  {
    path: "/settings",
    name: "settings",
    meta: { title: "Settings", authRequired: true },
    component: () => import("@/views/settings/Settings.vue"),
  },
  // Dashboard
  {
    path: "/",
    name: "default",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("@/views/pages/Home.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("@/views/dashboard/Dashboard.vue"),
  },
  // Organization
  {
    path: "/organization",
    name: "organization-index",
    meta: { title: "Organization", authRequired: true },
    component: () => import("@/views/organization/OrganizationList.vue"),
  },
  {
    path: "/organization/:organizationId",
    name: "organization-detail",
    meta: { title: "Organization Detail", authRequired: true },
    component: () => import("@/views/organization/OrganizationDetail.vue"),
  },
  {
    path: "/organization-detail",
    name: "organization-detail-selected",
    meta: { title: "Organization Detail", authRequired: true },
    component: () => import("@/views/organization/OrganizationDetail.vue"),
  },
  // Fleet
  {
    path: "/fleet-management",
    name: "fleet-management",
    meta: { title: "Fleet Management", authRequired: true },
    component: () => import("@/views/fleet/FleetList.vue"),
  },
  {
    path: "/fleet-realtime",
    name: "fleet-realtime",
    meta: { title: "Fleet Realtime Status", authRequired: true },
    component: () => import("@/views/fleet/FleetRealtime.vue"),
  },
  {
    path: "/fleet-emission",
    name: "fleet-emission",
    meta: { title: "Fleet Carbon Emission Report", authRequired: true },
    component: () => import("@/views/fleet/FleetEmission.vue"),
  },

  // Site
  {
    path: "/site",
    name: "site-index",
    meta: { title: "Site", authRequired: true },
    component: () => import("@/views/site/SiteList.vue"),
  },
  {
    path: "/organization/:organizationId/site/:siteId",
    name: "site-detail",
    meta: { title: "Site Detail", authRequired: true },
    component: () => import("@/views/site/SiteDetail.vue"),
  },
  {
    path: "/organization/:organizationId/site/:parentId/child/:siteId",
    name: "site-detail-child",
    meta: { title: "Site Detail", authRequired: true },
    component: () => import("@/views/site/SiteDetail.vue"),
  },
];