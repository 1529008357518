<template>
  <div>
    <b-spinner
      v-if="isLoading"
      class="position-absolute"
      style="top: 50%; left: 50%; width: 4rem; height: 4rem;"
    />
    <div class="table-responsive">
      <table
        :style="isLoading ? `filter: opacity(0.4)` : ''"
        class="table table-bordered table-hover"
        :class="tableClass"
      >
        <thead>
          <tr>
            <th
              v-for="(field, index) in fields"
              :key="index"
              scope="col"
              style="vertical-align: middle"
              :style="`min-width: ${field.thStyle?.minWidth ?? 'auto'}`"
              :class="field.class"
            >
              <div
                v-if="field.sortable"
                class="d-flex justify-content-between"
                style="cursor: pointer"
                @click="sortItems(field.key)"
              >
                <slot
                  :name="`header(${field.key})`"
                  :row="{ item: field }"
                >
                  <div v-if="field.label != ' '">
                    {{ field.label ?? field.key }}
                    <div
                      v-if="hasUnit"
                      class="fw-normal"
                      style="white-space: nowrap"
                    >
                      <small>
                        {{ field.unit || '-' }}
                      </small>
                    </div>
                  </div>
                </slot>
                <span
                  class="my-auto"
                  :class="field.label != ' ' ? '' : 'mx-auto'"
                > 
                  <small v-if="sortBy == field.key && direction == 'asc'">
                    <i class="bx bxs-up-arrow" />
                  </small>
                  <small v-else-if="sortBy == field.key && direction == 'desc'">
                    <i class="bx bxs-down-arrow" />
                  </small>
                  <i
                    v-else
                    class="bx bxs-sort-alt"
                  />
                </span>
              </div>
              <div v-else>
                <slot
                  :name="`header(${field.key})`"
                  :row="{ item: field }"
                >
                  {{ field.label ?? field.key }}
                  <div
                    v-if="hasUnit"
                    class="fw-normal"
                    style="white-space: nowrap"
                  >
                    <small>
                      {{ field.unit || '-' }}
                    </small>
                  </div>
                </slot>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(item, index) in sortedItems" 
            :key="index"
          >
            <td 
              v-for="(field, idx) in fields"
              :key="idx"
              style="vertical-align: middle"
            >
              <slot
                :name="`cell(${field.key})`"
                :row="{ item: item }"
              >
                {{ item[field.key] }}
              </slot>
            </td>
          </tr>
          <slot name="footer" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    tableClass: {
      type: String,
      default: 'thead-light'
    },
    allowLocalSort: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasUnit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      sortBy: null,
      direction: ''
    }
  },
  computed: {
    test() {
      return this.items.map(item => typeof item[this.sortBy])
    },
    sortedItems() {
      if(this.allowLocalSort) {
        if(this.direction == 'asc') {
          return [...this.items].sort((a, b) => { 
            if(a[this.sortBy] == null) { return -1 }
            if(b[this.sortBy] == null) { return 1 }

            if(typeof a[this.sortBy] === 'number' && typeof b[this.sortBy] === 'number') {
              return a[this.sortBy] - b[this.sortBy]
            }
            
            var varA = a[this.sortBy].toLowerCase()
            var varB = b[this.sortBy].toLowerCase();
            if (varA < varB)
              return -1;
            if (varA > varB)
              return 1;
            return 0;
          });
        } else if (this.direction == 'desc') {
          return [...this.items].sort((a, b) => {
            if(a[this.sortBy] == null) { return 1 }
            if(b[this.sortBy] == null) { return -1 }

            if(typeof a[this.sortBy] === 'number' && typeof b[this.sortBy] === 'number') {
              return b[this.sortBy] - a[this.sortBy]
            }
  
            var varA = a[this.sortBy].toLowerCase()
            var varB = b[this.sortBy].toLowerCase();
            if (varA < varB)
              return 1;
            if (varA > varB)
              return -1;
            return 0;
          });
        } return this.items 
      } else return this.items
    }
  },
  methods: {
    sortItems(key) {
      if (this.sortBy == key) {
        this.sortBy = this.direction == 'desc' ? null : key;
        this.direction = this.direction == 'asc' ? 'desc' : '';
      } else {
        this.sortBy = key;
        this.direction = 'asc';
      }
      this.$emit('sort-changed', { sortBy: this.sortBy, direction: this.direction })
    },
  },
};
</script>
