import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store";
import Axios from 'axios';

import BootstrapVue3 from 'bootstrap-vue-3';
import Popper from "vue3-popper";
import Toast from "vue-toastification";
import JsonExcel from "vue-json-excel3";

import Particles from "particles.vue3";

import ElementPlus from 'element-plus'
import '@/assets/scss/element/element.css'

import '@/assets/scss/config/material/app.scss';
import '@vueform/slider/themes/default.css';
import "vue-toastification/dist/index.css";

import SectionHeader from '@/components/SectionHeader.vue';
import DataTable from '@/components/DataTable.vue';

import { TokenService } from './services/local-storage';
import endpoints from './services/api'

// create axios instance
const instanceAxios = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    xsrfHeaderName: "X-XSRF-TOKEN",
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

const toastOptions = {
    timeout: 5000,
    icon: false,
    closeButton: false,
    hideProgressBar: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: false,
    toastClassName: "p-0 min-height-0"
};

// update token every request
instanceAxios.interceptors.request.use(
    (config) => {
      const token = TokenService.get();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error),
  );

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

const app = createApp(App)
    .use(store)
    .use(router)
    .use(BootstrapVue3)
    .component("downloadExcel", JsonExcel)
    .component("Popper", Popper)
    .component("SectionHeader", SectionHeader)
    .component("DataTable", DataTable)
    .use(Particles)
    .use(ElementPlus)
    .use(i18n)
    .use(Toast, toastOptions);

// global this.$api
app.config.globalProperties.$api = endpoints;

app.mount('#app')