<template>
  <div>
    <h5
      class="my-3 pb-2"
      style="border-bottom: 2px solid #CED4DA;"
    >
      {{ title }}
    </h5>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '', 
    },
  },
};
</script>